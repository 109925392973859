import { useContext, useState } from 'react'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BookOpenVariantIcon from 'mdi-material-ui/BookOpenVariant'
import AccountEditIcon from 'mdi-material-ui/AccountEdit'
import HandPointingRightIcon from 'mdi-material-ui/HandPointingRight'
import ClipboardPlayIcon from 'mdi-material-ui/ClipboardPlay'
import TimerIcon from 'mdi-material-ui/Timer'
import HumanGreetingIcon from 'mdi-material-ui/HumanGreeting'
import AccountPlusIcon from 'mdi-material-ui/AccountPlus'
import Schedule from '@material-ui/icons/Schedule'
import LabelImportantIcon from '@material-ui/icons/LabelImportant'
import ClipboardCheckIcon from 'mdi-material-ui/ClipboardCheck'
import FileMultipleIcon from 'mdi-material-ui/FileMultiple'
import { useStoreState } from 'easy-peasy'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import NotesIcon from '@material-ui/icons/Notes'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import EuroIcon from '@material-ui/icons/Euro'
import PiggyBankIcon from 'mdi-material-ui/PiggyBank'
import CardAccountPhone from 'mdi-material-ui/CardAccountPhone'
import range from '../../utils/range'
import { HashLink as Link } from 'react-router-hash-link'
import {
  padding,
  renderMenuItem,
  scale,
  scrollOffset,
  scrollWithOffset
} from '../../layout/Menu'
import { useTranslation } from 'react-i18next'
import { MenuContext } from './index'

const RecordPageMenu = () => {
  const { t } = useTranslation('recordPage')

  const [menuOpen, setMenuOpen] = useState(false)
  const { sessionNotesCounter, selfPayer, isZPM } = useStoreState((state) => ({
    sessionNotesCounter: state.user.sessionNotesCounter,
    selfPayer: state.user.selfPayer,
    isZPM: state.user.isZPM
  }))

  const { isVisibleMenuItem } = useContext(MenuContext)

  const renderSessionNotes = () => {
    return (
      <>
        <ListItem
          style={{ transform: `scale(${scale})`, padding: padding }}
          button
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <ListItemIcon>
            <NotesIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ variant: 'body2' }}
            primary={t('menuTreatmentNotes')}
          />
          {sessionNotesCounter > 0 &&
            (menuOpen ? <ExpandLess /> : <ExpandMore />)}
        </ListItem>
        <Collapse in={menuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {range(1, sessionNotesCounter).map((session) => (
              <Link
                key={session}
                style={{ textDecoration: 'none', color: 'black' }}
                smooth
                to={`#session${session}`}
                scroll={(el) => scrollWithOffset(el, scrollOffset)}
              >
                <ListItem
                  style={{ transform: `scale(${scale})`, padding: padding }}
                  button
                >
                  <ListItemText
                    primaryTypographyProps={{ variant: 'body2' }}
                    inset
                    primary={`${t('menuSession')} ${session}`}
                  />
                </ListItem>
              </Link>
            ))}
          </List>
        </Collapse>
      </>
    )
  }

  return (
    <List>
      {renderMenuItem(t('menuItemProgram'), 'treatmentProgram', AssignmentIcon)}

      {renderMenuItem(
        t('menuItemPatientDetails'),
        'patientDetails',
        BookOpenVariantIcon
      )}
      {isVisibleMenuItem('contactHistory')
        ? renderMenuItem(
            t('menuItemContactHistory'),
            'contactHistory',
            CardAccountPhone
          )
        : null}
      {!selfPayer &&
        renderMenuItem(
          t('menuItemHealthInsurance'),
          'healthInsurance',
          EuroIcon
        )}
      {renderMenuItem(t('menuItemTherapist'), 'therapist', AccountEditIcon)}
      {renderMenuItem(t('menuItemReferrer'), 'referrer', HandPointingRightIcon)}
      {renderMenuItem(
        t('menuItemStartProgram'),
        'startTreatmentProgram',
        ClipboardPlayIcon
      )}
      {renderMenuItem(
        t('menuItemTimeRegistration'),
        'timeRegistration',
        TimerIcon
      )}
      {renderMenuItem(
        t('menuItemAcquintanceNote'),
        'acquaintanceNote',
        HumanGreetingIcon
      )}
      {renderMenuItem(t('menuItemIntakeNote'), 'intakeNote', AccountPlusIcon)}
      {renderMenuItem(t('menuItemTreatmentPlan'), 'treatmentPlan', Schedule)}
      {renderSessionNotes()}
      {renderMenuItem(t('menuItemRemarks'), 'remarks', LabelImportantIcon)}
      {renderMenuItem(
        t('menuItemEndProgram'),
        'endTreatmentProgram',
        ClipboardCheckIcon
      )}
      {renderMenuItem(t('menuItemInvoice'), 'invoice', PiggyBankIcon)}
      {renderMenuItem(t('menuItemFiles'), 'files', FileMultipleIcon)}
    </List>
  )
}

export default RecordPageMenu
