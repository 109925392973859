import { client } from '../../../client'
import { useState } from 'react'
import { Button, IconButton, Menu } from '@material-ui/core'
import { GridCellParams } from '@material-ui/data-grid'
import { Build as EditIcon } from '@material-ui/icons'

import {
  NotificationBarContextType,
  useNotificationBarContext
} from '../../../components/NotificationBar/notificationBarContext'
import { RowData } from '.'
import { TRoleName } from '../../../types'

function changeRoles(
  row: RowData,
  showMessage: NotificationBarContextType['showMessage'],
  roles: TRoleName[]
) {
  showMessage('Changing user roles', 'info')
  client
    .changeUserRoles(row.id, roles)
    .then(() => showMessage('Changed user roles', 'success'))
    .catch(() => showMessage('Failed change user roles', 'error'))
}

export const RoleCell = (props: GridCellParams) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const { showMessage } = useNotificationBarContext()
  const open = Boolean(anchor)
  const row = props.row as RowData

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchor(null)
  }
  const handleRoleChange = (roles: TRoleName[]) => {
    closeMenu()
    changeRoles(row, showMessage, roles)
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        justifyContent: 'space-between'
      }}
    >
      <p
        style={{
          maxWidth: '80%',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {row.roles.toSorted().join(', ')}
      </p>
      <IconButton onClick={openMenu}>
        <EditIcon />
      </IconButton>

      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault()
            const formData = new FormData(event.target as any)
            handleRoleChange(formData.getAll('roles') as TRoleName[])
            window.location.reload() // todo - refresh the contents of the table without a full refresh
          }}
          style={{ padding: '1rem' }}
        >
          <div style={{ cursor: 'pointer' }}>
            <input
              type="checkbox"
              name="roles"
              value="SUPER_USER"
              id="SUPER_USER"
              defaultChecked={!!row.roles.find((v) => v === 'SUPER_USER')}
              style={{ cursor: 'pointer' }}
            />
            <label htmlFor="SUPER_USER" style={{ cursor: 'pointer' }}>
              Super User
            </label>
          </div>
          <div style={{ cursor: 'pointer' }}>
            <input
              type="checkbox"
              name="roles"
              value="ADMINISTRATOR"
              id="ADMINISTRATOR"
              defaultChecked={!!row.roles.find((v) => v === 'ADMINISTRATOR')}
              style={{ cursor: 'pointer' }}
            />
            <label htmlFor="ADMINISTRATOR" style={{ cursor: 'pointer' }}>
              Admin
            </label>
          </div>
          <div style={{ cursor: 'pointer' }}>
            <input
              type="checkbox"
              name="roles"
              value="BACKOFFICE"
              id="BACKOFFICE"
              defaultChecked={!!row.roles.find((v) => v === 'BACKOFFICE')}
              style={{ cursor: 'pointer' }}
            />
            <label htmlFor="BACKOFFICE" style={{ cursor: 'pointer' }}>
              Backoffice
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              name="roles"
              value="PRACTITIONER"
              id="PRACTITIONER"
              defaultChecked={!!row.roles.find((v) => v === 'PRACTITIONER')}
              style={{ cursor: 'pointer' }}
            />
            <label htmlFor="PRACTITIONER" style={{ cursor: 'pointer' }}>
              Practitioner
            </label>
          </div>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            style={{ gridColumn: '1/-1', marginTop: '1rem' }}
          >
            Change Roles
          </Button>
        </form>
      </Menu>
    </div>
  )
}
