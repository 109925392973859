import { useTranslation } from 'react-i18next'
import { format, isToday, isYesterday } from 'date-fns'
import { nl } from 'date-fns/locale'

const getHumanReadableDate = (date: number | Date) => {
  const { t } = useTranslation('backOfficePage')
  // return format(date, "dd-MM-yyyy HH:mm:ss", {});
  if (isToday(date)) {
    return t('dateToday')
  } else if (isYesterday(date)) {
    return t('dateYesterday')
  } else {
    return format(date, 'dd-MM', {
      locale: nl
    })
  }
}
export default getHumanReadableDate
