import { StatusLog } from '../../../BackOfficePage/BackOfficePageTable/StatusLog'

const ContactHistorySection = ({ funnel, statusLog }) => {
  return (
    <StatusLog
      showRemoveButton={false}
      funnel={funnel}
      statusLog={statusLog}
      open={true}
    />
  )
}

export default ContactHistorySection
