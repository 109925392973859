import AuthenticationService from '../../services/AuthenticationService'
import { Practice } from '../../types'

const restrictedPractices = {
  'Psychologenpraktijk Mulder': [
    'barbara@slaapmakend.nl',
    'arlene+test-mulder@slaapmakend.nl',
    'ksawery.brzeski+test-mulder@teacode.io'
  ]
}

export function currentUserCanAccessPractice(practice: Practice) {
  const userEmail = AuthenticationService.getEmail()

  if (restrictedPractices[practice.name]?.length) {
    return restrictedPractices[practice.name].includes(userEmail)
  }

  return true
}
