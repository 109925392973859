import { useContext, useState } from 'react'
import { useStoreActions } from 'easy-peasy'
import { client } from '../../client'
import useEffectAsync from '../../hooks/useEffectAsync'
import { useOnline } from '../../hooks/useOnline'
import { useNotificationBar } from '../../components/NotificationBar'
import { useTranslation } from 'react-i18next'
import { MenuContext } from './index'
import AuthenticationService from '../../services/AuthenticationService'
import getYear from 'date-fns/getYear'
import { Funnel, TreatmentProgram } from '../../types'

export function useRecordPageContent(match: {
  isExact: boolean
  params: {
    patientId: string
    treatmentProgramId: string
  }
  path: '/clienten/:patientId/trajecten/:treatmentProgramId'
  url: string //example: '/clienten/15529/trajecten/13820'
}) {
  const { t } = useTranslation('recordPage')

  const { setUpdatedJustNow } = useStoreActions((actions) => ({
    // @ts-ignore
    setUpdatedJustNow: actions.user.setUpdatedJustNow
  }))
  const { setIsZPM } = useStoreActions((actions) => ({
    // @ts-ignore
    setIsZPM: actions.user.setIsZPM
  }))
  const { treatmentProgramId } = match.params
  const [loading, setLoading] = useState(true)
  const [treatmentProgramAndFunnel, setTreatmentProgramAndFunnel] =
    useState<TreatmentProgram>()
  const [funnel, setFunnel] = useState<Funnel>()
  const [otherPatientFunnels, setOtherPatientFunnels] = useState<Funnel[]>()
  const { showMessage, NotificationBar } = useNotificationBar(undefined)
  const isOnline = useOnline()
  const { hideMenuItem } = useContext(MenuContext)
  const canRemoveRecord =
    AuthenticationService.isBackOffice() ||
    AuthenticationService.isAdministrator() ||
    AuthenticationService.isSuperUser()

  // Zorgprestatiemodel (ZPM) is the health care system from 2022 onwards.
  const isZPM =
    !treatmentProgramAndFunnel?.startDate ||
    getYear(new Date(treatmentProgramAndFunnel?.startDate)) >= 2022

  setIsZPM(isZPM)

  const save = async (values, errors) => {
    console.log('Saving', values)
    console.log('Errors', errors)

    if (!isOnline) {
      showMessage(t('offlineMessage'), 'error')
      return
    }
    if (!treatmentProgramAndFunnel) {
      showMessage(t('noResponseMessage'), 'error')
      return
    }

    try {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty('funnel') && treatmentProgramAndFunnel.funnel) {
        await client.updateFunnel(
          treatmentProgramAndFunnel.funnel.id,
          values.funnel
        )
      } else {
        await client.saveTreatmentProgram(treatmentProgramId, values)
      }
      setUpdatedJustNow(true)
      setTimeout(() => setUpdatedJustNow(false), 5000)
    } catch (e: any) {
      // If there's no response, the request failed
      if (!e.response) {
        showMessage(t('noResponseMessage'), 'error')
        return
      }

      // If there's a response, but it contains an error, something went wrong in the backend
      switch (e.response.status) {
        case 409:
          showMessage(t('error409Message'), 'error')
          break
        default:
          showMessage(t('errorMessage'), 'error')
          break
      }
    }
  }

  const handleRemovePatient = async (reason) => {
    if (
      !treatmentProgramAndFunnel ||
      !funnel ||
      !treatmentProgramAndFunnel.patient
    ) {
      showMessage(t('errorMessage'), 'error')
      return
    }
    await client.removeFunnel(funnel.id)
    await client.removePatient({
      id: treatmentProgramAndFunnel.patient.id,
      reason
    })
    window.location.href = '/'
  }

  useEffectAsync(async () => {
    setLoading(true)
    let assignedFunnel
    let otherFetchedPatientFunnels
    try {
      const treatment = await client.getTreatmentProgramById(treatmentProgramId)

      if (treatment?.patient) {
        otherFetchedPatientFunnels = await client.getFunnelsByPatientId(
          treatment.patient.id
        )
      }

      if (treatment) {
        assignedFunnel = await client.findFunnelByTreatmentProgramId(
          treatmentProgramId
        )

        treatment.funnel = assignedFunnel
        setFunnel(assignedFunnel)
      }
      setTreatmentProgramAndFunnel(treatment)
      setOtherPatientFunnels(otherFetchedPatientFunnels)
    } catch (e) {
      // No funnel for this client.
      hideMenuItem('contactHistory')
    }
    setLoading(false)
  }, [match.params.patientId])

  const allStatusLogs = otherPatientFunnels
    ?.map((funnel) =>
      funnel.statusLog.map((logItem) => ({
        treatmentProgramId: funnel.treatmentProgram?.id,
        ...logItem
      }))
    )
    .reduce((a, b) => [...a, ...b], [])
    .sort(
      (prev, curr) =>
        new Date(prev.createdAt).getTime() - new Date(curr.createdAt).getTime()
    )

  return {
    treatmentProgramAndFunnel,
    loading,
    save,
    canRemoveRecord,
    handleRemovePatient,
    mainFunnel: funnel,
    isZPM,
    NotificationBar,
    allStatusLogs,
    otherPatientFunnels
  }
}
