import { useTranslation } from 'react-i18next'
import { useFieldValue } from '../../../../hooks/useFieldValue'
import Grid from '@material-ui/core/Grid'
import { KeyboardDatePicker, TextField } from 'mui-rff'
import { isNumeric } from './helpers'
import InputAdornment from '@material-ui/core/InputAdornment'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import MinusCircleIcon from 'mdi-material-ui/MinusCircle'

const InvoiceRow = ({ name, onDelete, treatmentTypeId }) => {
  const { t } = useTranslation('invoiceSection')
  const { date, description, amount, vatPercentage, isEditDisabled } =
    useFieldValue(name)

  const isTouched = !!(date || description || amount || vatPercentage)
  const withoutEmptyRows =
    (treatmentTypeId === 5 || treatmentTypeId === 2) && !isTouched

  return (
    !withoutEmptyRows && (
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <KeyboardDatePicker
            name={`${name}.date`}
            label={t('dateLabel')}
            fieldProps={{
              validate: (value) =>
                isTouched && !value ? t('validateDateMessage') : undefined
            }}
            autoOk
            variant="inline"
            format="dd-MM-yyyy"
            invalidDateMessage={t('invalidDateMessage')}
            disabled={isEditDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name={`${name}.description`}
            label={t('descriptionLabel')}
            fieldProps={{
              validate: (value) =>
                isTouched && !value
                  ? t('validateDescriptionMessage')
                  : undefined
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            disabled={isEditDisabled}
            fieldProps={{
              validate: (value) =>
                isTouched && !value
                  ? t('validateAmountMessage')
                  : isTouched && !isNumeric(value)
                  ? t('amountTypeMessage')
                  : undefined,
              parse: (value) => (value ? value.replace(',', '.') : null),
              format: (value) => (value ? value.replace('.', ',') : null)
            }}
            name={`${name}.amount`}
            label={t('amountExampleLabel')}
            InputProps={{
              // @ts-ignore
              startAdornment: <InputAdornment>€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <TextField
            fullWidth
            name={`${name}.vatPercentage`}
            label="BTW%"
            fieldProps={{
              validate: (value) =>
                isTouched && !value ? t('amountRequired') : undefined
            }}
            disabled={treatmentTypeId === 5}
          />
        </Grid>
        <Box style={{ marginLeft: 'auto' }} alignSelf="center">
          <Grid item xs={1}>
            {!isEditDisabled && (
              <IconButton onClick={onDelete} size="small">
                <MinusCircleIcon fontSize="small" color="disabled" />
              </IconButton>
            )}
          </Grid>
        </Box>
      </Grid>
    )
  )
}

export default InvoiceRow
