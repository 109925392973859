import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import './styles.css'

export const Activity2022Header = () => {
  const { t } = useTranslation('timeRegistrationSection')

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        {t('startTime')}
      </Grid>

      <Grid item xs={2}>
        {t('consultationType')}
      </Grid>

      <Grid item xs={2}>
        {t('psychologist')}
      </Grid>

      <Grid item xs={1}>
        {t('occupation')}
      </Grid>

      <Grid item xs={1}>
        {t('setting')}
      </Grid>

      <Grid item xs={1}>
        {t('duration')}
      </Grid>

      <Grid item xs={1}>
        {t('consultationPrice')}
      </Grid>
    </Grid>
  )
}
