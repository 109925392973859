import { useState } from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormSection from '../FormSection'
import IconButton from '@material-ui/core/IconButton'
import { Collapse } from '@material-ui/core'

export const CollapsibleSection = ({
  component: Component,
  title,
  anchor,
  defaultExpand = false
}) => {
  const [expanded, setExpanded] = useState(defaultExpand)
  const toggleExpand = () => setExpanded(!expanded)
  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon

  return (
    <FormSection
      action={
        <IconButton onClick={toggleExpand}>
          <ExpandIcon
            fontSize="small"
            color="disabled"
            aria-expanded={expanded}
            aria-label={expanded ? 'Collapse' : 'Expand'}
          />
        </IconButton>
      }
      anchor={anchor}
      title={title}
      render={() => (
        <Collapse in={expanded}>
          <Component />
        </Collapse>
      )}
    />
  )
}
