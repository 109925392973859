import React, { useState } from 'react'
import RecordPageMenu from './RecordPageMenu'
import { RecordPageContent } from './RecordPageContent'
import Page from '../../layout/Page'

type TMenuContext = {
  hideMenuItem: (item: any) => void
  isVisibleMenuItem: (item: any) => boolean
}
export const MenuContext = React.createContext<TMenuContext>({
  isVisibleMenuItem: () => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  hideMenuItem: () => {}
})

export const RecordPage = () => {
  const [menuState, setMenuState] = useState<{ hidden: any[] }>({
    hidden: []
  })

  const hideMenuItem = (item) =>
    setMenuState({
      hidden: [...menuState.hidden, item]
    })

  const isVisibleMenuItem = (item) => !menuState.hidden.includes(item)

  return (
    <MenuContext.Provider value={{ hideMenuItem, isVisibleMenuItem }}>
      <Page menu={<RecordPageMenu />} content={<RecordPageContent />} />
    </MenuContext.Provider>
  )
}
