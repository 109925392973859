import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { PasswordField } from '../../../components/PasswordField'
import { client } from '../../../client'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useNotificationBar } from '../../../components/NotificationBar'
import { useTranslation } from 'react-i18next'
import AuthenticationService from '../../../services/AuthenticationService'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2)
  },
  container: {
    padding: theme.spacing(3)
  }
}))

const ChangePasswordSection = ({ match }) => {
  const { t } = useTranslation('settingsPage')

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const classes = useStyles()
  const { showMessage, NotificationBar } = useNotificationBar({
    vertical: 'bottom',
    horizontal: 'center'
  })

  const handleSubmit = async () => {
    if (newPassword !== newPasswordConfirm) {
      showMessage(t('passwordsNotTheSame'), 'error')
      return
    }
    const errors = await client.checkPasswordStrength(newPassword)
    if (errors.length) {
      showMessage(errors[0], 'error')
      return
    }
    try {
      await client.changePassword(
        AuthenticationService.getUserId(),
        oldPassword,
        newPassword
      )
      showMessage(t('passwordUpdated'))
    } catch (e) {
      if (e.response && e.response.status && e.response.status === 401) {
        showMessage(t('oldPasswordIncorrect'), 'error')
      } else {
        showMessage(t('somethingWentWrong'), 'error')
      }
    }
  }

  return (
    <div className={classes.container}>
      <Typography component="p" variant="body2">
        {t('newPasswordValidation')}
      </Typography>
      <List disablePadding dense>
        <ListItem>
          <ListItemText inset>• 8 {t('characters')}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText inset>• 1 {t('number')}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText inset>• 1 {t('lowercase')}</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText inset>• 1 {t('capitalLetter')}</ListItemText>
        </ListItem>
      </List>
      <Typography component="p" variant="body2">
        {t('passwordBlackList')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={3} />
        <Grid item md={6}>
          <PasswordField
            id="oldPassword"
            label={t('oldPassword')}
            type="password"
            onChange={(event) => setOldPassword(event.target.value)}
            fullWidth
            required
          />
          <PasswordField
            id="newPassword"
            label={t('newPassword')}
            type="password"
            onChange={(event) => setNewPassword(event.target.value)}
            fullWidth
            required
          />
          <PasswordField
            id="newPasswordRepeat"
            label={t('newPasswordRepeat')}
            type="password"
            onChange={(event) => setNewPasswordConfirm(event.target.value)}
            fullWidth
            required
          />
          <Button
            onClick={handleSubmit}
            variant="outlined"
            color="primary"
            style={{
              textTransform: 'none',
              display: 'block',
              margin: 'auto',
              marginTop: 10
            }}
          >
            {t('buttonChangeText')}
          </Button>
          {NotificationBar}
        </Grid>
        <Grid item md={3} />
      </Grid>
    </div>
  )
}

export default withRouter(ChangePasswordSection)
