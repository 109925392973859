import { client } from '../../../client'
import { useState } from 'react'
import { Button, IconButton, Menu } from '@material-ui/core'
import { GridCellParams } from '@material-ui/data-grid'
import { Build as EditIcon } from '@material-ui/icons'

import { useNotificationBarContext } from '../../../components/NotificationBar/notificationBarContext'
import { RowData } from '.'

export const ActiveCell = (props: GridCellParams) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const { showMessage } = useNotificationBarContext()
  const open = Boolean(anchor)
  const row = props.row as RowData

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }
  const closeMenu = () => {
    setAnchor(null)
  }
  const toggleActivity = async () => {
    closeMenu()
    const res = await client.updateUser(row.id, { isActive: !row.isActive })
    showMessage(res.isActive ? 'Activated user!' : 'Deactivated user!', 'info')
    window.location.reload() // todo - refresh the contents of the table without a full refresh
  }

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        justifyContent: 'space-between'
      }}
    >
      <p
        style={{
          maxWidth: '80%',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
      >
        {row.isActive ? '✅ Active' : '❌ Deactivated'}
      </p>
      <IconButton onClick={openMenu}>
        <EditIcon />
      </IconButton>

      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div style={{ padding: '1rem' }}>
          <Button color="primary" variant="outlined" onClick={toggleActivity}>
            {row.isActive ? '⚠️ Deactivate' : '⚠️ Activate'}
          </Button>
        </div>
      </Menu>
    </div>
  )
}
