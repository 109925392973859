import { StatusContext } from '../BackOfficePageContent'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import React from 'react'

interface IStatusSelectorProps {
  label?: string
  value: number
  onChange: (event: any) => void
}

const StatusSelector: React.FunctionComponent<IStatusSelectorProps> = ({
  label = '',
  value = 5,
  onChange
}) => {
  return (
    <StatusContext.Consumer>
      {(options: Array<any>) => (
        <FormControl
          style={{
            width: 150
          }}
        >
          {label && <InputLabel>{label}</InputLabel>}
          <Select disableUnderline value={value} onChange={onChange}>
            {options
              .filter(
                (option) =>
                  option.stage === 'CONTACTING' || option.value === value
              )
              .map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  style={{ whiteSpace: 'normal' }}
                >
                  <Typography style={{ marginBottom: -5 }} variant="body2">
                    {option.label.replace(/(.{15})..+/, '$1…')}
                  </Typography>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </StatusContext.Consumer>
  )
}

export default StatusSelector
