import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { AlertDialog } from '../../../components/AlertDialog'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

export const DeletePatientDialog = ({ onConfirm, patientName }) => {
  const { t } = useTranslation('recordPage')
  const [reason, setReason] = useState()

  const handleChange = (e) => {
    setReason(e.target.value)
  }

  const handleConfirm = () => {
    onConfirm(reason)
  }

  return (
    <AlertDialog
      text={t('alertText')}
      title={t('alerTitle')}
      cancelText={t('alertCancel')}
      confirmText={t('alertConfirm')}
      onConfirm={handleConfirm}
      validationText={patientName}
      disableSubmit={!reason}
      content={
        <TextField
          required
          fullWidth
          multiline
          rows={4}
          variant={'outlined'}
          label={t('deleteReason')}
          onChange={handleChange}
          style={{ marginTop: '20px' }}
        />
      }
      render={({ onClick }) => (
        <IconButton onClick={onClick}>
          <DeleteIcon fontSize="small" color="disabled" />
        </IconButton>
      )}
    />
  )
}
