import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'

interface ITooltipButtonProps {
  icon: any
  title: string | JSX.Element
}
const TooltipButton: React.FunctionComponent<ITooltipButtonProps> = ({
  icon: Icon,
  title
}) => (
  <Tooltip
    style={{ backgroundColor: '#fff' }}
    interactive
    title={title}
    placement="right-start"
  >
    <IconButton size="small">
      <Icon fontSize="small" color="disabled" />
    </IconButton>
  </Tooltip>
)

export default TooltipButton
