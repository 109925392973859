// we don't want to rerender entire RecordPageContent every time selfPayer changes
// so this wrapper makes the state local to this component avoiding the unnecessary re-renders
import { useTranslation } from 'react-i18next'
import FormSection from '../FormSection'
import HealthInsuranceSection from '../sections/HealthInsuranceSection/HealthInsuranceSection'
import { useStoreState } from 'easy-peasy'

export const HealthInsuranceSectionWrapper = () => {
  const { t } = useTranslation('recordPage')
  // @ts-ignore todo - types for ease-peasy
  const selfPayer = useStoreState((state) => state.user.selfPayer)

  return (
    <FormSection
      hidden={selfPayer}
      anchor="healthInsurance"
      title={t('formHealthInsurance')}
      component={HealthInsuranceSection}
    />
  )
}
