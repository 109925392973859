import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { withRouter } from 'react-router-dom'
import { AutoSave } from '../../components/AutoSave'
import FormActions from '../FormActions'
import FormSection from './FormSection'
import { TreatmentProgramSection } from './sections/TreatmentProgramSection'
import { TreatmentStatusSection } from './sections/TreatmentStatusSection'
import { PatientDetailsSection } from './sections'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { PageLoadingIndicator } from '../../components/PageLoadingIndicator'
import TherapistSection from './sections/TherapistSection/TherapistSection'
import ReferrerSection from './sections/ReferrerSection/ReferrerSection'
import StartTreatmentProgramSection from './sections/StartTreatmentProgramSection/StartTreatmentProgramSection'
import NotesSection from './sections/NotesSection/NotesSection'
import TreatmentPlanSection from './sections/TreatmentPlanSection/TreatmentPlanSection'
import SessionNotesSection from './sections/SessionNotesSection/SessionNotesSection'
import EndTreatmentProgramSection from './sections/EndTreatmentProgramSection/EndTreatmentProgramSection'
import { FilesSection } from './sections/FilesSection'
import TimeRegistrationSection from './sections/TimeRegistrationSection/TimeRegistrationSection'
import TimeRegistration2022Section from './sections/TimeRegistration2022Section/TimeRegistration2022Section'
import InvoiceSection from './sections/InvoiceSection/InvoiceSection'
import { AcquaintanceSection } from './sections/AcquaintanceSection'
import { useTranslation } from 'react-i18next'
import ContactHistorySection from './sections/ContactHistorySection/ContactHistorySection'

import { useRecordPageContent } from './useRecordPageContent'
import { CollapsibleSection } from './components/CollapsibleSection'
import { HealthInsuranceSectionWrapper } from './components/HealthInsuranceSectionWrapper'
import { DeletePatientDialog } from './components/DeletePatientDialog'

const useStyles = makeStyles(() => ({
  deleteIcon: {
    position: 'absolute',
    right: 25
  }
}))

const validate = (values) => {
  const errors: { patient: { bsn?: string } } = { patient: {} }
  const bsn = values.patient.bsn
  if (bsn && (bsn.length < 8 || bsn.length > 9)) {
    errors.patient.bsn = 'Een geldig BSN is 8 of 9 getallen lang.'
  }
  return errors
}

export const RecordPageContent = withRouter(({ match }) => {
  const { t } = useTranslation('recordPage')
  const classes = useStyles()

  const {
    treatmentProgramAndFunnel,
    loading,
    save,
    canRemoveRecord,
    handleRemovePatient,
    mainFunnel,
    isZPM,
    NotificationBar,
    otherPatientFunnels,
    allStatusLogs
  } = useRecordPageContent(match as any)

  if (loading) return <PageLoadingIndicator />

  if (!treatmentProgramAndFunnel)
    return (
      <Typography
        component="h1"
        variant="h6"
        align="left"
        color="textSecondary"
        gutterBottom
        style={{ marginLeft: 15 }}
      >
        {t('treatmentProgramNotFound')}
      </Typography>
    )

  return (
    <Form
      initialValues={treatmentProgramAndFunnel}
      onSubmit={save}
      subscription={{ submitting: true, pristine: true, invalid: true }}
      validate={validate}
      validateOnBlur
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        }
      }}
      render={({ handleSubmit, form: { getState, mutators } }) => {
        const patient = getState().values.patient
        const patientName = [patient.firstName, patient.infix, patient.lastName]
          .filter((part) => !!part) // ignore falsy parts (empty string, null)
          .map((part) => part.trim())
          .join(' ')
        return (
          <div>
            <Grid container direction="row">
              <Grid item>
                <Typography
                  component="h1"
                  variant="h6"
                  align="left"
                  color="textSecondary"
                  gutterBottom
                  style={{ marginLeft: 15 }}
                >
                  {patientName}
                </Typography>
              </Grid>
              {canRemoveRecord && (
                <Grid item>
                  <div className={classes.deleteIcon}>
                    <DeletePatientDialog
                      patientName={patientName}
                      onConfirm={handleRemovePatient}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <form onSubmit={handleSubmit} autoComplete="off">
              <AutoSave
                debounce={1000}
                save={(values) => save(values, getState().errors)}
              />
              <FormActions />
              <div className="form">
                {mainFunnel ? (
                  <FormSection
                    anchor="treatmentStatus"
                    title={t('formTreatmentStatus')}
                    render={() => (
                      <TreatmentStatusSection funnel={mainFunnel} />
                    )}
                  />
                ) : null}
                <FormSection
                  anchor="treatmentProgram"
                  title={t('formTreatmentProgram')}
                  component={TreatmentProgramSection}
                />
                <FormSection
                  anchor="patientDetails"
                  title={t('formPatientDetails')}
                  component={PatientDetailsSection}
                />
                <HealthInsuranceSectionWrapper />
                <FormSection
                  anchor="therapist"
                  title={t('formTherapist')}
                  component={TherapistSection}
                />
                <FormSection
                  anchor="referrer"
                  title={t('formReferrer')}
                  component={ReferrerSection}
                />
                <FormSection
                  anchor="startTreatmentProgram"
                  title={t('formStartTreatment')}
                  component={StartTreatmentProgramSection}
                  initialPrivacyStatementFile={
                    getState().values.privacyStatementFile
                  }
                  initialTreatmentAgreementFile={
                    getState().values.treatmentAgreementFile
                  }
                  initialHoNOSResultFile={getState().values.hoNOSResultFile}
                />
                <CollapsibleSection
                  defaultExpand={!isZPM}
                  anchor="timeRegistration"
                  title={t('formTimeRegistration')}
                  component={TimeRegistrationSection}
                />
                <CollapsibleSection
                  defaultExpand={isZPM}
                  anchor="timeRegistration"
                  title={t('formTimeRegistration2022')}
                  component={TimeRegistration2022Section}
                />
                <FormSection
                  anchor="acquaintanceNote"
                  title={t('formAcquintanceNote')}
                  component={AcquaintanceSection}
                />
                <FormSection
                  anchor="intakeNote"
                  title={t('formIntakeNote')}
                  name="intakeNote"
                  component={NotesSection}
                />
                <FormSection
                  anchor="treatmentPlan"
                  title={t('formTreatmentPlan')}
                  component={TreatmentPlanSection}
                  name="treatmentPlan"
                />
                <SessionNotesSection />
                <FormSection
                  anchor="remarks"
                  title={t('formRemarks')}
                  component={NotesSection}
                  name="remarks"
                />
                <FormSection
                  anchor="endTreatmentProgram"
                  title={t('formEndTreatment')}
                  component={EndTreatmentProgramSection}
                />
                <FormSection
                  anchor="invoice"
                  title={t('formInvoice')}
                  render={() => (
                    <InvoiceSection
                      zpm={isZPM}
                      treatmentType={treatmentProgramAndFunnel?.treatmentType}
                    />
                  )}
                />
                <FormSection
                  anchor="files"
                  title={t('formFiles')}
                  component={FilesSection}
                />
                {mainFunnel || otherPatientFunnels?.[0] ? (
                  // we merge contact history of all funnels assigned to this patient, not only this treatment program
                  <FormSection
                    anchor="contactHistory"
                    title={t('contactHistory')}
                    funnel={mainFunnel || otherPatientFunnels?.[0]}
                    statusLog={allStatusLogs}
                    component={ContactHistorySection}
                  />
                ) : null}
              </div>
            </form>
            {NotificationBar}
          </div>
        )
      }}
    />
  )
})
