import './styles.css'
import { client } from '../../../client'
import { AdminPage } from '../../../layout/AdminPage'
import { useAutosave } from './useAutosave'
import {
  usePracticeLocationOptions,
  usePsychologistOptions
} from './initialData'
import { useMemo, useState } from 'react'
import { Psychologist } from '../../../types'
import { PsychologistTable } from './PsychologistTable'
import ChevronLeft from '@material-ui/icons/ChevronLeft'

const psychologistNameSortable = (psychologist: Psychologist) =>
  `${psychologist.user.firstName} ${psychologist.user.lastName}`

const PsychologistListContent = (): JSX.Element => {
  client.clearCacheItem('allPsychologistOptions')
  client.clearCacheItem('activePsychologistOptions')
  client.clearCacheItem('practiceOptions')

  const psychologists = usePsychologistOptions()
  const locations = usePracticeLocationOptions()

  const [filter, setFilter] = useState('')

  const filteredPsychologists = useMemo(() => {
    return psychologists
      .filter((p) =>
        JSON.stringify(p).toLowerCase().includes(filter.toLowerCase())
      )
      .sort((a, b) =>
        psychologistNameSortable(a).localeCompare(psychologistNameSortable(b))
      )
  }, [filter, psychologists])

  const [deactivatedVisible, setDeactivatedVisible] = useState(false)

  const activePsychologists = useMemo(() => {
    return filteredPsychologists.filter((p) => p.user.isActive)
  }, [filteredPsychologists])

  const inactivePsychologists = useMemo(() => {
    return filteredPsychologists.filter((p) => !p.user.isActive)
  }, [filteredPsychologists])

  const onChange = useAutosave()

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      onChange={onChange}
      style={{ marginBottom: '8rem' }}
    >
      <p>⚠️ Note: Agb Code must be unique (cannot repeat)</p>
      <label>Filter: </label>
      <input
        type="text"
        onChange={(e) => {
          e.stopPropagation()
          setFilter(e.target.value)
        }}
      />
      <h2> Active psychologists </h2>
      <PsychologistTable psychologists={activePsychologists} />
      <h2
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '2rem',
          cursor: 'pointer'
        }}
        onClick={() => setDeactivatedVisible((v) => !v)}
      >
        Deactivated psychologists{' '}
        <ChevronLeft
          style={{
            transform: deactivatedVisible ? 'rotate(90deg)' : 'rotate(-90deg)'
          }}
        />
      </h2>
      {deactivatedVisible ? (
        <PsychologistTable psychologists={inactivePsychologists} />
      ) : (
        <h3
          onClick={() => setDeactivatedVisible((v) => !v)}
          style={{
            cursor: 'pointer'
          }}
        >
          Expand to edit
        </h3>
      )}
    </form>
  )
}

const PsychologistListPage = (): JSX.Element => {
  return (
    <AdminPage>
      <PsychologistListContent />
    </AdminPage>
  )
}

export default PsychologistListPage
