import { client } from '../../../client'
import { useState } from 'react'
import { Button, IconButton, MenuItem, Menu } from '@material-ui/core'
import { GridCellParams } from '@material-ui/data-grid'
import { Menu as MenuIcon } from '@material-ui/icons'

import { useNotificationBarContext } from '../../../components/NotificationBar/notificationBarContext'

export const MenuCell = (props: GridCellParams) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const { showMessage } = useNotificationBarContext()
  const open = Boolean(anchor)
  const email = props.row.email as string
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget)
  }
  const handleClose = () => {
    setAnchor(null)
  }
  const handleReset = () => {
    handleClose()
    showMessage('Resetting credentials...', 'info')
    client
      .resetCredentials(email)
      .then(() => showMessage('Credentials reset', 'success'))
      .catch(() => showMessage('Failed to reset credentials', 'error'))
  }
  return (
    <div>
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu open={open} onClose={handleClose} anchorEl={anchor}>
        <MenuItem>
          <Button color="primary" variant="outlined" onClick={handleReset}>
            Reset Credentials
          </Button>
        </MenuItem>
      </Menu>
    </div>
  )
}
