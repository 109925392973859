import {
  DateCheckbox,
  Field,
  RichTextEditor
} from '../../../../react-final-form-wrappers'
import FormRow from '../../FormRow'
import { useTranslation } from 'react-i18next'
import { FilesSection } from '../FilesSection'

const TreatmentPlanSection = () => {
  const { t } = useTranslation('treatmentPlanSection')

  return (
    <>
      <FormRow>
        <Field
          name="treatmentPlan"
          label={t('treatmentPlan')}
          component={RichTextEditor}
        />
      </FormRow>
      <FormRow>
        <Field
          style={{ top: -20, left: 20 }}
          name="treatmentPlanConsentDate"
          label={t('treatmentPlanDate')}
          component={DateCheckbox}
        />
      </FormRow>

      <FormRow>
        <FilesSection
          typesToShow={['startLetter']}
          uploadedFileType={'startLetter'}
        />
      </FormRow>
    </>
  )
}

export default TreatmentPlanSection
