import Input from '@material-ui/core/Input'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from '@material-ui/core/Button'
import { PlusCircle } from 'mdi-material-ui'
import { FieldArray } from 'react-final-form-arrays'
import Link from '@material-ui/core/Link'
import { client } from '../../client'
import { makeStyles } from '@material-ui/core/styles'
import { Field } from '../../react-final-form-wrappers'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'
import { AxiosResponse } from 'axios'
import { TFileType } from '../../utils/fileTypes'

export const useStyles = makeStyles(() => ({
  selectedOption: {
    marginTop: 10,
    marginLeft: 15,
    marginRight: 10
  }
}))

export type TFilesSectionProps = {
  uploadedFileType: TFileType // even though this list displays all files of typesToShow, the uploadedFileType is used to determine the type of file that is uploaded
  typesToShow?: TFileType[]
  label?: string
  funnelId: number
}

// funnel
export const FilesSection = ({
  label,
  typesToShow,
  uploadedFileType,
  funnelId
}: TFilesSectionProps) => {
  const { t } = useTranslation('filesSection')

  const handleSubmit = async (
    event: ChangeEvent<HTMLInputElement>,
    push: (data: AxiosResponse) => void
  ) => {
    const files = (event.target as HTMLInputElement).files
    if (!files) {
      console.error('No files selected')
      return
    }
    // using for loop because FileList is not iterable
    for (let i = 0; i < files.length; i++) {
      client
        .uploadFile(files[i], {
          funnelId,
          documentType: uploadedFileType || 'other'
        })
        .then((response) => push(response.data))
    }
  }

  const classes = useStyles()

  return (
    <FieldArray
      name={'files'}
      render={({ fields }) => (
        <div style={{ marginTop: 10 }}>
          {fields.map((name, index) => (
            <Field
              name={`${name}`}
              key={name}
              render={({ input: { value } }) => {
                return (
                  value && (
                    <div
                      style={{
                        overflow: 'hidden',
                        display:
                          !typesToShow || typesToShow.includes(value.type)
                            ? 'block'
                            : 'none'
                      }}
                    >
                      <Typography
                        className={classes.selectedOption}
                        style={{ float: 'left' }}
                        component="p"
                        variant="body1"
                      >
                        <Link
                          // todo this should have the actual document as a href + additionally have download={true}
                          onClick={() =>
                            client.fetchFile(value.id, value.filename)
                          }
                        >
                          {value.filename}
                        </Link>
                      </Typography>
                      <IconButton
                        style={{ float: 'left' }}
                        onClick={() =>
                          client.removeFile(fields.remove(index).id)
                        }
                      >
                        <DeleteIcon color="disabled" fontSize="small" />
                      </IconButton>
                    </div>
                  )
                )
              }}
            />
          ))}
          <Button color="primary" size="small" component="label">
            <PlusCircle />
            {label || t('otherFile')}
            <Input
              inputProps={{
                multiple: true
              }}
              name="otherFile"
              id="otherFile"
              type="file"
              style={{ display: 'none' }}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleSubmit(event, fields.push)
              }
            />
          </Button>
        </div>
      )}
    />
  )
}
