import { Field, useField } from 'react-final-form'
import { Select } from '../../../../react-final-form-wrappers'
import { client } from '../../../../client'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useNotificationBar } from '../../../../components/NotificationBar'
import { DateTimePicker } from './DateTimePickerOld'
import { ConsultationPriceField } from './ConsultationPriceField'
import PsychologistSelector from '../../../../components/PsychologistSelector/PsychologistSelector'
import * as types from '../../../../types'
import './styles.css'
import { useOptionsContext } from './optionsContext'
import { useFieldValue } from '../../../../hooks/useFieldValue'

function notUndefined<T>(x: T | undefined): x is T {
  return typeof x !== 'undefined'
}

type DurationOptionsFilter = (options: types.Duration[]) => types.Duration[]
const getDurationOptionsFilter = (
  activity: types.Activity2022,
  treatmentType: types.TreatmentType,
  consultationPrices: types.ConsultationPrice[],
  year: number
): DurationOptionsFilter => {
  // TODO: these ids definitely shouldn't be hardcoded
  if (activity.consultationType?.id === 3) {
    // for Intercollegiaal overleg
    return (options) => {
      return options.filter((option) => option.id === 1 || option.id === 2)
    }
  } else if (activity.consultationType?.id === 4) {
    // for No-show
    return (options) => {
      return options.filter((option) => option.id === 9 || option.id === 10)
    }
  } else if (treatmentType.id === 2) {
    // for uninsured treatment
    return (options) => {
      return options.filter(
        (option) => option.id !== 9 && option.id !== 1 && option.id !== 10
      )
    }
  }

  // TODO: this shouldn't be recalculated for every activity
  const availablePrices = consultationPrices.filter(
    (price) =>
      price.consultationType?.id === activity.consultationType?.id &&
      price.year === year
  )
  const availableDurationIds = new Set(
    availablePrices.map((p) => p.duration?.id).filter(notUndefined)
  )
  return (durations: types.Duration[]) =>
    durations.filter((d) => availableDurationIds.has(d.id))
}

type ConsultationTypeOptionsFilter = (
  types: types.ConsultationType[]
) => types.ConsultationType[]

const getConsultationTypeOptions = (
  treatmentType: types.TreatmentType
): ConsultationTypeOptionsFilter => {
  if (treatmentType.id === 2) {
    // for uninsured treatment
    return (options) => {
      return options.filter((option) => option.id !== 3)
    }
  }
  return (options) => options
}

const getDurationLabel = (duration: types.Duration): string => {
  if (duration.id === 9) return '-'
  if (duration.id === 10) return 'coulance'
  return duration.value + ' minuten'
}

const EditableActivity2022 = ({ name, handleRemoveActivity }) => {
  const { t } = useTranslation('timeRegistrationSection')
  const { showMessage, NotificationBar } = useNotificationBar({})

  const {
    input: { value: treatmentType }
  } = useField('treatmentType')
  const {
    input: { value: activity }
  } = useField<types.Activity2022>(name)

  const {
    consultationTypeOptions,
    occupationTypeOptions,
    settingTypeOptions,
    durationOptions,
    consultationPrices
  } = useOptionsContext()

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Field
          name={`${name}.date`}
          component={DateTimePicker as any}
          disabled={!!activity.invoiceRow}
          onClick={() =>
            !!activity.invoiceRow && showMessage(t('notEditableInputText'))
          }
        />
      </Grid>

      <Grid item xs={2}>
        <Select
          options={consultationTypeOptions}
          optionsFilter={getConsultationTypeOptions(treatmentType)}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name={`${name}.consultationType.id`}
          disabled={!!activity.invoiceRow}
          onClick={() =>
            !!activity.invoiceRow && showMessage(t('notEditableInputText'))
          }
        />
      </Grid>

      <Grid item xs={2}>
        <PsychologistSelector
          name={`${name}.psychologist.id`}
          disabled={!!activity.invoiceRow}
        />
      </Grid>

      <Grid item xs={1}>
        <Select
          options={occupationTypeOptions}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name={`${name}.occupationType.id`}
          fieldProps={{
            defaultValue: occupationTypeOptions.find(
              (option) => option.id === 5
            )?.id
          }}
          disabled={!!activity.invoiceRow}
          onClick={() =>
            !!activity.invoiceRow && showMessage(t('notEditableInputText'))
          }
        />
      </Grid>

      <Grid item xs={1}>
        <Select
          options={settingTypeOptions}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name={`${name}.settingType.id`}
          disabled={!!activity.invoiceRow}
          onClick={() =>
            !!activity.invoiceRow && showMessage(t('notEditableInputText'))
          }
        />
      </Grid>

      <Grid item xs={1}>
        <Select
          options={durationOptions}
          optionsFilter={getDurationOptionsFilter(
            activity,
            treatmentType,
            consultationPrices,
            new Date(activity.date).getFullYear()
          )}
          getOptionLabel={getDurationLabel}
          getOptionValue={(i) => i.id}
          fieldProps={{
            defaultValue: activity.consultationType?.id === 4 ? 9 : undefined
          }}
          name={`${name}.duration.id`}
          disabled={!!activity.invoiceRow}
          onClick={() =>
            !!activity.invoiceRow && showMessage(t('notEditableInputText'))
          }
        />
      </Grid>

      <Grid item xs={1}>
        <ConsultationPriceField rootName={name} />
      </Grid>

      <Grid item xs={1}>
        {!activity.invoiceRow && (
          <IconButton aria-label="Delete" onClick={handleRemoveActivity}>
            <DeleteIcon fontSize="small" color="disabled" />
          </IconButton>
        )}
      </Grid>
      {NotificationBar}
    </Grid>
  )
}

type Activity2022Props = {
  name: string
  index: number
  remove: (index: number) => void
}

const Activity2022 = ({
  name,
  index,
  remove
}: Activity2022Props): JSX.Element => {
  const activity = useFieldValue(name)

  const handleRemoveActivity = useCallback(() => {
    remove(index) // remove from the form
    client.removeActivity2022(activity.id) // send a request
  }, [activity.id, index, remove])

  return (
    <EditableActivity2022
      handleRemoveActivity={handleRemoveActivity}
      name={name}
    />
  )
}

export default Activity2022
