import React, { useState } from 'react'
import { client } from '../../../client'
import useEffectAsync from '../../../hooks/useEffectAsync'
import BackOfficePagination from '../BackOfficePagination'
import StatusBlock from './StatusBlock'
import { Funnel } from '../../../types'
import DragableStatusBlock from './Dragable/DragableStatusBlock'

export default function BackOfficePageTable({ status, forceUpdate, update }) {
  const handleStatusChange = async ({ funnelId, statusId }) => {
    const data = await client.updateFunnelStatus(funnelId, statusId)
    if (data.status !== 'success') {
      return
    }

    setPage(1)
    setPages(1)
    forceUpdate()
  }

  const [page, setPage] = useState(1)
  const handleChange = (event, value) => setPage(value)
  const [funnels, setFunnels] = useState<Array<Funnel>>([])
  const [pages, setPages] = useState<number>()

  useEffectAsync(async () => {
    await fetchFunnels(status, setFunnels, setPages)
  }, [status, page, update])

  const fetchFunnels = async (status, setFunnels, setPages) => {
    const result = await client.getFunnels(status.id, page)
    setFunnels(result.data)
    setPages(result.pages)
    if (!pages) {
      console.error('Failed to fetch pages')
      return
    }
  }

  return funnels.length ? (
    <>
      {status.name === 'Algemene wachtlijst' ? (
        <DragableStatusBlock
          anchor={status.id}
          key={status.id}
          funnels={funnels}
          name={status.name}
          onStatusChange={handleStatusChange}
          forceUpdate={forceUpdate}
        />
      ) : (
        <StatusBlock
          anchor={status.id}
          key={status.id}
          funnels={funnels}
          name={status.name}
          onStatusChange={handleStatusChange}
        />
      )}
      <BackOfficePagination
        pages={pages}
        page={page}
        handleChange={handleChange}
      />
    </>
  ) : null
}
