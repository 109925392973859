import { useState } from 'react'
import { client } from '../client'
import useEffectAsync from './useEffectAsync'
import * as types from '../types'

export type Options = {
  consultationTypeOptions: types.ConsultationType[]
  occupationTypeOptions: types.OccupationType[]
  settingTypeOptions: types.SettingType[]
  durationOptions: types.Duration[]
  consultationPrices: types.ConsultationPrice[]
  psychologistOptions: types.Psychologist[]
}

export const useOptions = (
  isLegacy: boolean
): Options & { loading: boolean } => {
  // options are grouped together to prevent multiple rerenders
  // since before React 18, each state update triggers a rerender
  // when called outside of browser events and we're still using React 17
  const [options, setOptions] = useState<Options & { loading: boolean }>({
    consultationTypeOptions: [],
    occupationTypeOptions: [],
    settingTypeOptions: [],
    durationOptions: [],
    consultationPrices: [],
    psychologistOptions: [],
    loading: true
  })

  useEffectAsync(async () => {
    const [
      consultationTypeOptions,
      consultationPrices,
      durationOptions,
      settingTypeOptions,
      occupationTypeOptions,
      psychologistOptions
    ] = await Promise.all([
      client.getConsultationTypeOptions(),
      client.getConsultationPrices(),
      client.getDurationOptions(),
      client.getSettingTypeOptions(),
      client.getOccupationTypeOptions(),
      isLegacy
        ? client.getPsychologistOptions()
        : client.getPsychologistOptions(true)
    ])

    setOptions({
      consultationTypeOptions,
      consultationPrices,
      durationOptions,
      settingTypeOptions,
      occupationTypeOptions,
      psychologistOptions,
      loading: false
    })
  }, [isLegacy])

  return options
}
