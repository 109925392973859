import { useEffect, useState } from 'react'

export function useAsyncData<T>(
  f: () => Promise<T>
): [T, false] | [undefined, true] {
  const [data, setData] = useState<T | undefined>()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    f().then((data) => {
      setData(data)
      setLoading(false)
    })
  }, [])
  return [data, loading] as [T, false] | [undefined, true]
}
