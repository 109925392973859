import { useTranslation } from 'react-i18next'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { default as MuiTextField } from '@material-ui/core/TextField/TextField'

export const TherapistSelector = ({ options, onChange }) => {
  const { t } = useTranslation('patientDetailsPage')

  const getFullName = (option) => {
    const { firstName, infix, lastName } = option.user
    return [firstName, infix, lastName].join(' ')
  }

  const sortedOptions = options
    .filter((option) => !!option.user)
    .sort((a, b) => getFullName(a).localeCompare(getFullName(b)))

  return (
    <Autocomplete
      options={sortedOptions}
      getOptionLabel={(option) => getFullName(option)}
      style={{ width: 300 }}
      onChange={(event, value) => {
        onChange(value)
      }}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={t('practicioner')}
          variant="outlined"
        />
      )}
    />
  )
}
