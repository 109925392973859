export const invoiceTypes = {
  FULL: 'FULL',
  PARTIAL: 'PARTIAL'
}

export function isNumeric(num) {
  return !isNaN(num)
}
export const invoiceStatusOptions = [
  { id: 1, description: 'Factuur aangemaakt', name: 'CREATED' },
  { id: 2, description: 'Factuur verstuurd', name: 'SENT' },
  { id: 3, description: 'Factuur betaald', name: 'PAID' }
]

export const getFullName = (psychologist) => {
  if (!psychologist.user) {
    return ''
  }
  const { initials, infix, lastName } = psychologist.user
  return [initials, infix, lastName].join(' ')
}
