import { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { AlertDialog } from './AlertDialog'
import warning from 'warning'
import { LoadingIndicator } from './LoadingIndicator'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  input: {
    display: 'none'
  },
  checkbox: {
    color: theme.palette.primary.main + '!important'
  }
}))

const renderDeleteButton = ({ onClick }) => (
  <IconButton style={{ marginLeft: 5 }} aria-label="Delete" onClick={onClick}>
    <DeleteIcon fontSize="small" color="disabled" />
  </IconButton>
)

type FileUploadCheckboxProps = {
  label: string
  value: {
    id: string
    uuid: string
    filename: string
    mimeType: string
    createdAt: any
    lastModifiedAt: any
    treatmentProgram: any
  }
  onSubmit: (file: any) => any | Promise<any> // callback executed when new file is submitted
  onChange: (value: any) => void | Promise<void> // callback executed when value changes (always after onSubmit)
  onDelete: (value: any) => void | Promise<void> // callback executed when file is deleted (calls onChange afterwards)
}
/**
 * Control the type of value stored by passing in onSubmit.
 *
 * Examples:
 *     - if you only want the filename stored, return the filename in onSubmit.
 *     - if you submit the file to an API, you may want to store the returned record
 *
 * The updated `value` is then emitted to `onChange`.
 */
//   todo - replace everywhere with the FileList (component, not the primitive)
export const FileUploadCheckbox = ({
  label,
  value,
  onSubmit,
  onChange,
  onDelete
}: FileUploadCheckboxProps) => {
  const { t } = useTranslation('fileUploadButtonComponent')

  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  warning(
    onChange,
    "FileUploadCheckbox is a controlled content, but onChange wasn't set"
  )

  const handleSelectFile = async (event) => {
    setLoading(true)
    const targetFiles = event.target.files
    for (let i = 0; i < targetFiles.length; i++) {
      if (onSubmit) await onSubmit(targetFiles[i])
      if (onChange) await onChange(targetFiles[i])
    }
    setLoading(false)
  }

  const handleRemoveFile = async () => {
    setLoading(true)
    if (onDelete) await onDelete(value)
    if (onChange) onChange(undefined)
    setLoading(false)
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div>
      <Checkbox
        disabled
        checked={!!value}
        color="primary"
        className={classes.checkbox}
      />
      <Button color="primary" component="label" className={classes.button}>
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={handleSelectFile}
        />
        <AttachFileIcon />
        {label}
      </Button>
      {value && (
        <AlertDialog
          text={t('alertDialogText')}
          title={t('alertDialogTitle')}
          cancelText={t('alertDialogCancel')}
          confirmText={t('alertDialogConfirm')}
          onConfirm={handleRemoveFile}
          render={renderDeleteButton}
        />
      )}
    </div>
  )
}
