import { useState } from 'react'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { useStoreActions } from 'easy-peasy'
import { withRouter } from 'react-router-dom'
import { client } from '../../../client'
import useEffectAsync from '../../../hooks/useEffectAsync'
import AutoSave from '../../../components/AutoSave/AutoSave'
import FormSection from '../../RecordPage/FormSection'
import PatientDetailsSection from '../PatientDetailsSection'
import { TreatmentStatusSection } from '../../RecordPage/sections/TreatmentStatusSection'
import Typography from '@material-ui/core/Typography'
import { PageLoadingIndicator } from '../../../components/PageLoadingIndicator'
import { useOnline } from '../../../hooks/useOnline'
import { TextField } from 'mui-rff'
import { useNotificationBar } from '../../../components/NotificationBar'
import Box from '@material-ui/core/Box'
import { DatePicker, Field, Select } from '../../../react-final-form-wrappers'
import FormRow from '../../RecordPage/FormRow'
import { useTranslation } from 'react-i18next'
import AuthenticationService from '../../../services/AuthenticationService'
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition'
import * as types from '../../../types'
import { AssignTherapistDialog } from './AssignTherapistDialog'
import { DeletePatientDialog } from '../../RecordPage/components/DeletePatientDialog'
import { FilesSection } from '../FilesSection'

export * from './TherapistSelector'

const validate = (values) => {
  const errors = {}
  const bsn = values.bsn
  if (bsn && (bsn.length < 8 || bsn.length > 9)) {
    errors['bsn'] = 'Een geldig BSN is 8 of 9 getallen lang.'
  }
  return errors
}

const PatientDetailsPageContent = ({ match }) => {
  const { t } = useTranslation('patientDetailsPage')
  const classes = useStyles()

  const { setUpdatedJustNow } = useStoreActions((actions: any) => ({
    setUpdatedJustNow: actions.user.setUpdatedJustNow
  }))
  const { funnelId } = match.params
  const [loading, setLoading] = useState(true)
  const [funnel, setFunnel] = useState<types.Funnel | undefined>()
  const { showMessage, NotificationBar } = useNotificationBar(undefined)
  const isOnline = useOnline()
  const [loadingAssignPsychologist, setLoadingAssignPsychologist] =
    useState(false)

  const patient = funnel?.patient

  const canRemoveRecord =
    AuthenticationService.isBackOffice() ||
    AuthenticationService.isAdministrator() ||
    AuthenticationService.isSuperUser()

  const save = async (values, errors) => {
    console.log('Saving', values)
    console.log('Errors', errors)

    if (!isOnline) {
      showMessage(t('offlineMessage'), 'error')
      return
    }

    try {
      await client.updateFunnel(funnelId, values)
      setUpdatedJustNow(true)
      setTimeout(() => setUpdatedJustNow(false), 5000)
    } catch (e: any) {
      // If there's no response, the request failed
      if (!e.response) {
        showMessage(t('responseFailMessage'), 'error')
        return
      }

      // If there's a response, but it contains an error, something went wrong in the backend
      switch (e.response.status) {
        case 409:
          showMessage(t('response409ErrorMessage'), 'error')
          break
        default:
          showMessage(t('responseErrorMessage'), 'error')
          break
      }
    }
  }

  const handleRemovePatient = async (reason) => {
    await client.removeFunnel(funnel?.id)
    await client.removePatient({ id: funnel?.patient.id, reason })

    window.location.href = '/'
  }

  useEffectAsync(async () => {
    setLoading(true)
    try {
      const fetchedFunnel = await client.getFunnelById(funnelId)
      setFunnel(fetchedFunnel)
    } catch (e) {}
    setLoading(false)
  }, [match.params.patientId])

  if (loading) return <PageLoadingIndicator />

  if (!funnel)
    return (
      <Typography
        component="h1"
        variant="h6"
        align="left"
        color="textSecondary"
        gutterBottom
        style={{ marginLeft: 15 }}
      >
        {t('funnelmNotFound')}
      </Typography>
    )

  return (
    <Form
      initialValues={funnel}
      onSubmit={save}
      subscription={{ submitting: true, pristine: true, invalid: true }}
      validate={validate}
      validateOnBlur
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        }
      }}
      render={({ handleSubmit, form: { getState, mutators } }) => {
        const patientName = [
          patient?.firstName,
          patient?.infix,
          patient?.lastName
        ]
          .filter((part) => !!part) // ignore falsy parts (empty string, null)
          .map((part: any) => part.trim())
          .join(' ')
        return (
          <div>
            <Box display="flex">
              <Box flexGrow={1}>
                <Typography
                  component="h1"
                  variant="h6"
                  align="left"
                  color="textSecondary"
                  gutterBottom
                  style={{ marginLeft: 15 }}
                >
                  {[patient?.firstName, patient?.infix, patient?.lastName].join(
                    ' '
                  )}
                </Typography>
              </Box>
              <Box>
                {canRemoveRecord && (
                  <div className={(classes as any).deleteIcon}>
                    <DeletePatientDialog
                      patientName={patientName}
                      onConfirm={handleRemovePatient}
                    />
                  </div>
                )}
              </Box>
            </Box>
            <form onSubmit={handleSubmit} autoComplete="off">
              <AutoSave
                debounce={1000}
                save={(values) => save(values, getState().errors)}
              />
              <div className="form">
                <FormSection
                  anchor="treatmentStatus"
                  title={t('formTreatmentStatus')}
                  render={() => <TreatmentStatusSection funnel={funnel} />}
                />
                <FormSection
                  anchor="patientDetails"
                  title={t('formTitle')}
                  render={() => (
                    <>
                      <PatientDetailsSection />
                      <FormRow>
                        <Select
                          label={t('practiceLabel')}
                          getOptionLabel={(i) => i.name}
                          getOptionValue={(i) => i.id}
                          getOptions={async () =>
                            client.getPracticeOptions(true)
                          }
                          name="practice.id"
                        />
                        {/* <Select */}
                        {/*   label={t('locationLabel')} */}
                        {/*   getOptionLabel={(i) => i.name} */}
                        {/*   getOptionValue={(i) => i.id} */}
                        {/*   getOptions={getValidLocationOptions} */}
                        {/*   name="location.id" */}
                        {/* /> */}
                      </FormRow>
                      {/* <HealthInsuranceSection /> */}
                      <FormRow>
                        <Field
                          style={{ width: 300 }}
                          name="registrationDate"
                          label={t('registrationDate')}
                          component={DatePicker}
                        />
                      </FormRow>
                      <FormRow>
                        <TextField
                          fullWidth
                          multiline
                          name="remarks"
                          label={t('remarksLabel')}
                        />
                      </FormRow>
                      <FormRow>
                        <FilesSection
                          funnelId={funnelId}
                          uploadedFileType={'referralLetter'}
                          label={t('referral')}
                          typesToShow={['referralLetter']}
                        />
                        <FilesSection
                          funnelId={funnelId}
                          uploadedFileType={'other'}
                          typesToShow={['other']}
                        />
                      </FormRow>
                    </>
                  )}
                />
                <AssignTherapistDialog
                  setLoadingAssignPsychologist={setLoadingAssignPsychologist}
                  loadingAssignPsychologist={loadingAssignPsychologist}
                  funnel={funnel}
                />
              </div>
            </form>
            {NotificationBar}
          </div>
        )
      }}
    />
  )
}

export default withRouter(PatientDetailsPageContent)
