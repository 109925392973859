import { validateEmail } from '../../utils/validate'
import { client } from '../../client'
import AuthenticationService from '../../services/AuthenticationService'

const validate = async (values: {
  phoneNumber: number
  email: string
  status: any
}) => {
  const errors: Partial<Record<keyof typeof values, string>> = {}
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Voer ten minste een telefoonnummer in.'
  }
  if (values.email && !validateEmail(values.email)) {
    errors.email = 'Het opgegeven e-mailadres is ongeldig.'
  }
  if (!values.status) {
    errors.status = 'Geef een status op.'
  }

  //if any of the fields are missing, simply return here
  if (
    Object.keys(errors) &&
    Object.keys(errors)?.length &&
    Object.keys(errors)?.length > 0
  ) {
    return errors
  }

  // if user is *not* just a regular practitioner, create patients with non-unique email or phone
  if (
    AuthenticationService.isBackOffice() ||
    AuthenticationService.isAdministrator() ||
    AuthenticationService.isSuperUser()
  ) {
    return errors
  }

  //otherwise, validate uniqueness
  if (values.email && validateEmail(values.email)) {
    const data = await client.findPatientByEmailOrPhoneNumber({
      email: values.email
    })
    const patientId = data?.data?.patient?.id
    if (patientId) {
      errors.email =
        'Het opgegeven emailadres is al bekend. Mail naar info@ om toegang te krijgen tot het dossier.' // t('emailTaken')
    }
  }
  if (values.phoneNumber) {
    const data = await client.findPatientByEmailOrPhoneNumber({
      phoneNumber: values.phoneNumber
    })
    const patientId = data?.data?.patient?.id
    if (patientId) {
      errors.phoneNumber =
        'Het opgegeven telefoonnummer is al bekend. Mail naar info@ om toegang te krijgen tot het dossier.' //t('phoneNumberTaken')
    }
  }
  return errors
}

export default validate
