import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import FunnelTableRow from './FunnelTableRow'
import React from 'react'
import { Funnel } from '../../../types'

interface IFunnelTableProps {
  funnels: Array<Funnel>
  onStatusChange: any
}
const FunnelTable: React.FunctionComponent<IFunnelTableProps> = ({
  funnels,
  onStatusChange
}) => {
  return (
    <Table size="small">
      <TableBody>
        {funnels.map((funnel) => (
          <FunnelTableRow
            key={funnel.id}
            funnel={funnel}
            onStatusChange={onStatusChange}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default FunnelTable
