import { useEffect, useState } from 'react'
import FormRow from '../../FormRow'
import {
  DatePicker,
  Field,
  Select
} from '../../../../react-final-form-wrappers'
import useEffectAsync from '../../../../hooks/useEffectAsync'
import { client } from '../../../../client'
import { FormSpy, useField } from 'react-final-form'
import AsyncSelect from 'react-select/async'
import Hidden from '@material-ui/core/Hidden'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import HelpOutline from '@material-ui/icons/HelpOutline'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { useTranslation } from 'react-i18next'
import { FilesSection } from '../FilesSection'
import { sentryDebugLog } from '../../../../utils/sentryDebugLog'

const debug = (message) => sentryDebugLog(message, 'ReferrerSection')
const ReferrerSection = (props) => {
  const { t } = useTranslation('referrerSection')

  const [referrerName, setReferrerName] = useState('')
  const [selfReferrer, setSelfReferrer] = useState(false)
  const {
    input: { value: referrerAgb }
  } = useField('referrerAgb')
  const {
    input: { value: programId }
  } = useField('id')

  // no idea if it does something, let's fix it some other time
  useEffectAsync(async () => {
    const name = referrerAgb ? handleReferrerAgbChange(referrerAgb) : ''
    // @ts-ignore
    setReferrerName(name)
  }, [])

  const handleReferrerAgbChange = async (agb) => {
    if (!agb || agb.length !== 8) {
      setReferrerName('')
      return
    }
    const details = await client.findPractitionerByAgb(agb)
    if (Object.keys(details).length === 0) {
      setReferrerName('')
    }
    const { Voorletters, Tussenvoegsel, Achternaam } = details
    const name = [Voorletters, Tussenvoegsel, Achternaam].join(' ')
    setReferrerName(name)
  }

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          useEffect(() => {
            setSelfReferrer(
              values.referrerType ? values.referrerType.id === 6 : false
            )
          }, [values.referrerType])

          return <></>
        }}
      />
      {!selfReferrer && (
        <FormRow>
          <Field
            style={{ width: 300 }}
            name="referralDate"
            label={t('referralDate')}
            component={DatePicker}
          />
        </FormRow>
      )}

      <FormRow>
        <Select
          style={{ width: 300 }}
          label={t('referenceType')}
          getOptions={async () => client.getReferrerTypeOptions()}
          getOptionLabel={(i) => `${i.code}: ${i.description}`}
          getOptionValue={(i) => i.id}
          name="referrerType.id"
        />
      </FormRow>

      {!selfReferrer && (
        <div>
          <Field
            style={{ width: 300 }}
            name="referrerAgb"
            render={({ input: { onChange, ...restInput }, meta }) => (
              <TextField
                {...restInput}
                label={t('agbCode')}
                onChange={(event) => {
                  handleReferrerAgbChange(event.target.value)
                  onChange(event)
                }}
              />
            )}
          />
          <TextField
            disabled
            style={{ marginLeft: 20 }}
            label={t('referrerName')}
            value={referrerName}
          />
          <Tooltip
            interactive
            title={
              <Link
                target="_blank"
                href="https://www.agbcode.nl/Webzoeker/Zoeken"
                color="inherit"
                style={{ textDecoration: 'none' }}
              >
                <Typography color="inherit">{t('searchRegister')}</Typography>
                <Typography variant="body2">{t('registerInfo')}</Typography>
              </Link>
            }
            placement="right-start"
          >
            <IconButton>
              <HelpOutline fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      )}

      <Hidden xlDown>
        <FormRow>
          <div style={{ width: 300 }}>
            <Field
              name="referrer"
              render={({ input, meta }) => (
                <AsyncSelect
                  {...input}
                  style={{ width: '100%' }}
                  cacheOptions
                  defaultOptions
                  label={t('referrer')}
                  loadOptions={(value) =>
                    value.length >= 3
                      ? client.searchReferrers(value)
                      : Promise.resolve([])
                  }
                  getOptionLabel={(i: any) => i.name}
                  getOptionValue={(i: any) => i.id}
                />
              )}
            />
          </div>
        </FormRow>
      </Hidden>

      {!selfReferrer && (
        <FormRow>
          <FilesSection
            uploadedFileType={'referralLetter'}
            typesToShow={['referralLetter']}
          />
        </FormRow>
      )}
    </>
  )
}

export default ReferrerSection
