import { useHistory } from 'react-router-dom'
import PracticeIndicator from '../PracticeIndicator'
import React, { Fragment, useState } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import getFullName from '../getFullName'
import getHumanReadableDate from '../getHumanReadableDate'
import EmailTooltip from '../EmailTooltip'
import PhoneTooltip from '../PhoneTooltip'
import StatusSelector from '../StatusSelector'
import { StatusLog } from '../StatusLog'
import isOnWatchList from '../isOnWatchList'
import { Funnel } from '../../../../types'
import VerticalAlignBottomSharpIcon from '@material-ui/icons/VerticalAlignBottomSharp'
import ArrowRightAltSharpIcon from '@material-ui/icons/ArrowRightAltSharp'

interface IDragableFunnelTableRow {
  funnel: Funnel
  onStatusChange: any
  moveOneUp?: any
  moveOneDown?: any
  moveTop?: any
  moveBottom?: any
}

const DragableFunnelTableRow: React.FunctionComponent<
  IDragableFunnelTableRow
> = (props) => {
  const { funnel, onStatusChange } = props
  const history = useHistory()
  const [open, setOpen] = useState(false)

  const handleClick = () => history.push(`/wachtlijst/${funnel.id}`)

  const { patient, statusLog, practice, location, status } = funnel
  const lastLogItem = statusLog[statusLog.length - 1]

  return (
    <Fragment>
      <TableRow key={funnel.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen((state) => !state)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          align="right"
          padding="none"
          style={{ verticalAlign: 'middle' }}
        >
          <PracticeIndicator practiceName={practice.name} />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'middle',
            width: '50%',
            cursor: 'pointer'
          }}
        >
          <span
            style={{
              verticalAlign: 'middle',
              paddingRight: 24
            }}
            onClick={handleClick}
          >
            {getFullName(patient)}
          </span>
          <div style={{ display: 'inline-flex', verticalAlign: 'middle' }}>
            <IconButton
              size="small"
              onClick={props.moveTop}
              style={{
                rotate: '180deg',
                cursor: 'pointer',
                display: open ? 'block' : 'none'
              }}
            >
              <VerticalAlignBottomSharpIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={props.moveOneUp}
              style={{
                rotate: '-90deg',
                cursor: 'pointer',
                display: open ? 'block' : 'none'
              }}
            >
              <ArrowRightAltSharpIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={props.moveOneDown}
              style={{
                rotate: '90deg',
                cursor: 'pointer',
                display: open ? 'block' : 'none'
              }}
            >
              <ArrowRightAltSharpIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={props.moveBottom}
              style={{ cursor: 'pointer', display: open ? 'block' : 'none' }}
            >
              <VerticalAlignBottomSharpIcon />
            </IconButton>
          </div>
        </TableCell>
        <TableCell
          align="right"
          style={{ verticalAlign: 'bottom', width: '22%' }}
        >
          {lastLogItem.user.firstName}
        </TableCell>
        <TableCell
          align="right"
          style={{ verticalAlign: 'bottom', width: '12%' }}
        >
          {getHumanReadableDate(new Date(lastLogItem.createdAt))}
        </TableCell>
        {isOnWatchList(status) && (
          <TableCell align="right" style={{ verticalAlign: 'bottom' }}>
            {location?.abbreviation}
          </TableCell>
        )}
        <TableCell style={{ verticalAlign: 'bottom', width: '5%' }}>
          {patient.email && <EmailTooltip email={patient.email} />}
        </TableCell>
        <TableCell style={{ verticalAlign: 'bottom', width: '5%' }}>
          {patient.phoneNumber && <PhoneTooltip phone={patient.phoneNumber} />}
        </TableCell>
        <TableCell style={{ verticalAlign: 'bottom' }}>
          <StatusSelector
            value={funnel.status.id}
            onChange={(event) =>
              onStatusChange({
                funnelId: funnel.id,
                statusId: event.target.value
              })
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottomWidth: 0 }}
          colSpan={12}
        >
          <StatusLog
            funnel={funnel}
            statusLog={funnel.statusLog}
            open={open}
            practice={practice}
            location={location}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default DragableFunnelTableRow
