import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import renderComponent from '../../utils/renderComponent'
import CardHeader from '@material-ui/core/CardHeader'
import { makeStyles } from '@material-ui/core/styles'
import React, { memo } from 'react'

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 20,
    minWidth: 275
  },
  title: {
    fontSize: 14,
    marginTop: -10,
    marginLeft: -16,
    color: theme.palette.text.secondary
  },
  action: {
    marginTop: -30,
    marginRight: -30
  },
  overflow: 'visible' as any
}))

type FormSectionProps = {
  title?: React.ReactNode
  component?: React.ReactNode
  render?: React.FunctionComponent
  children?: React.ReactNode
  hidden?: boolean
  anchor?: string
  action?: React.ReactNode
} & any // can take some weird additional props that are passed down

const FormSection = memo(
  ({
    title,
    component,
    render,
    children,
    hidden,
    anchor,
    action,
    ...rest
  }: FormSectionProps) => {
    const classes = useStyles()
    const renderProps = {}

    const renderSection = () => (
      <Card className={classes.card} style={{ overflow: 'visible' }}>
        <CardContent>
          <CardHeader
            classes={{ title: classes.title, action: classes.action }}
            title={title}
            action={action}
          />
          {renderComponent({
            ...renderProps,
            children,
            component,
            render,
            ...rest
          })}
        </CardContent>
      </Card>
    )

    if (hidden) {
      return <></>
    } else {
      return anchor ? (
        <>
          <a href={`#${anchor}`} id={anchor}>
            <div />
          </a>
          {renderSection()}
        </>
      ) : (
        renderSection()
      )
    }
  }
)

export default FormSection
