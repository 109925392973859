import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { useCallback } from 'react'
import * as types from '../../../../types'
import './styles.css'
import { OptionsContextProvider } from './optionsContext'
import Activity2022 from './Activity2022'
import NewActivityButton from './NewActivityButton'
import { Activity2022Header } from './Activity2022Header'

type Fields = FieldArrayRenderProps<types.Activity2022, HTMLElement>['fields']
type FieldsProps = { fields: Fields }

const Activity2022List = ({ fields }: FieldsProps): JSX.Element => {
  const remove = fields.remove
  const renderActivity2022 = useCallback(
    (name: string, index: number) => (
      // TODO: the key should somehow get the activity id
      <Activity2022 name={name} key={name} index={index} remove={remove} />
    ),
    [remove]
  )
  return <>{fields.map(renderActivity2022)}</>
}

const TimeRegistration2022SectionContent = ({
  fields
}: FieldsProps): JSX.Element => (
  <>
    <Activity2022Header />
    <Activity2022List fields={fields} />
    <NewActivityButton push={fields.push} />
  </>
)

const TimeRegistration2022Section = (): JSX.Element => (
  <OptionsContextProvider>
    <FieldArray<types.Activity2022>
      name="activities2022"
      component={TimeRegistration2022SectionContent}
    />
  </OptionsContextProvider>
)

export default TimeRegistration2022Section
