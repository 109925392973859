import { client } from '../../../client'
import { useEffect, useState } from 'react'
import { Paper, Box, Typography, TextField } from '@material-ui/core'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { NotificationBarProvider } from '../../../components/NotificationBar/notificationBarContext'
import { AdminPage } from '../../../layout/AdminPage'
import { RoleCell } from './RoleCell'
import { MenuCell } from './MenuCell'
import { ActiveCell } from './ActiveCell'

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First Name', flex: 0.6 },
  { field: 'lastName', headerName: 'Last Name', flex: 0.6 },
  { field: 'email', headerName: 'Email', flex: 1 },
  {
    field: 'isActive',
    headerName: 'Active',
    flex: 0.4,
    renderCell: ActiveCell
  },
  { field: 'roles', headerName: 'Roles', flex: 1, renderCell: RoleCell },
  {
    field: 'actions',
    headerName: ' ',
    renderCell: MenuCell
  }
]

export type RowData = {
  id: number
  firstName: string
  lastName: string
  email: string
  isActive: boolean
  roles: string[]
}

const formatUserData = (
  data: Awaited<ReturnType<typeof client.getAllUsers>>
): RowData[] => {
  return data.map((u) => ({
    ...u,
    roles: u.roles.map((r) => r.name)
  }))
}

const UserListContent = () => {
  const [users, setUsers] = useState<RowData[]>([])
  const [search, setSearch] = useState('')
  useEffect(() => {
    client.getAllUsers().then(formatUserData).then(setUsers)
  }, [])
  const filteredData = users.filter((d) =>
    JSON.stringify(d).toLowerCase().includes(search.toLowerCase())
  )
  return (
    <Paper style={{ height: 400, width: '100%' }}>
      <NotificationBarProvider>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            gap: '12px',
            padding: '12px'
          }}
        >
          <TextField
            variant="outlined"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Typography variant="h5">List of registered users</Typography>
        </Box>
        <Box style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
          <DataGrid
            columns={columns}
            rows={filteredData}
            autoPageSize={true}
            disableSelectionOnClick
          />
        </Box>
      </NotificationBarProvider>
    </Paper>
  )
}

const UserListPage = (): JSX.Element => (
  <AdminPage>
    <UserListContent />
  </AdminPage>
)

export default UserListPage
