import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import { useState } from 'react'
import { useField } from 'react-final-form'
import { client } from '../../client'
import { useAsyncData } from '../../utils/useAsyncData'
import { LoadingIndicator } from '../LoadingIndicator'
import { Psychologist } from '../../types'

const getFullName = (psychologist: any) => {
  if (!psychologist.user) {
    return ''
  }
  const { firstName, infix, lastName } = psychologist.user
  return [firstName, infix, lastName].filter(Boolean).join(' ')
}

type PsychologistSelectorProps = {
  label?: string
  name: string
  style?: React.CSSProperties
  disabled?: boolean
}

const psychologistSortFn = (a, b) => {
  if (!a.user) {
    return -1
  }
  if (!b.user) {
    return 1
  }
  return getFullName(a).localeCompare(getFullName(b))
}

const PsychologistSelector = ({
  label,
  name,
  style,
  disabled
}: PsychologistSelectorProps): JSX.Element => {
  // TODO: this should be a prop
  const {
    input: { value: isLegacy }
  } = useField('isLegacy')
  const field = useField(name)
  const [initialId] = useState(field.input.value)
  const [options, loading] = useAsyncData<Psychologist[]>(
    async () => client.getPsychologistOptions()
    // we don't filter for active psychologists on the backend.
    // Instead, the filter is on the frontend (below)
    // to include the currently assigned psychologist,
    // even though their account may be deactivated
  )

  if (loading) return <LoadingIndicator />

  const sortedOptions = options
    ?.filter((o: any) => !!o.user)
    ?.filter((o: any) => isLegacy || o.user.isActive || o.user.id === initialId)
    ?.toSorted(psychologistSortFn)

  return (
    <Autocomplete
      style={style}
      options={sortedOptions}
      getOptionLabel={getFullName}
      onChange={(_, value) => field.input.onChange(value?.id)}
      defaultValue={options.find((p: any) => p.id === initialId)}
      renderInput={(params) => <TextField {...params} label={label} />}
      disabled={disabled}
    />
  )
}

export default PsychologistSelector
