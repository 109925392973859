import {
  Checkbox,
  DatePicker,
  Field,
  Select
} from '../../../../react-final-form-wrappers'
import FormRow from '../../FormRow'
import { client } from '../../../../client'
import Divider from '@material-ui/core/Divider'
import { Radios, TextField } from 'mui-rff'
import DisorderSection from '../DisorderSection/DisorderSection'
import { useField, useFormState } from 'react-final-form'
import Button from '@material-ui/core/Button'
import { AlertDialog } from '../../../../components/AlertDialog'
import LetterTemplate from './LetterTemplate'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { FilesSection } from '../FilesSection'

const StartTreatmentProgramSection = () => {
  const { t } = useTranslation('startTreatmentProgramSection')
  const {
    input: { value: programId }
  } = useField('id')

  const program = useFormState().values

  const missingFields = Object.values({
    primaryPsychologist: program?.primaryPsychologist
      ? ''
      : t('primaryPsychologist'),
    patientFirstName: program?.patient?.firstName ? '' : t('firstName'),
    patientLastName: program?.patient?.lastName ? '' : t('lastName'),
    birthDate: program?.patient?.birthDate ? '' : t('birthDate'),
    gender: program?.patient?.gender ? '' : t('gender'),
    street: program?.patient?.street ? '' : t('street'),
    residence: program?.patient?.residence ? '' : t('residence'),
    houseNumber: program?.patient?.houseNumber ? '' : t('houseNumber'),
    startDate: program?.startDate ? '' : t('startDate'),
    practice: program?.practice ? '' : t('practice')
  })
    .filter((field) => !!field) // filter fields that are not missing
    .join(', ')

  const [hoNOS, setHoNOS] = useState()

  useEffect(() => {
    ;(async () => {
      const types = await client.getHoNOSTypes()

      const values = types.map((type) => {
        return {
          label: type.name === 'full' ? t('HoNOSFull') : t('HoNOSShort'),
          value: type.id.toString()
        }
      })
      setHoNOS(values)
    })()
  }, [])

  return (
    <>
      <FormRow>
        <Field
          style={{ width: 300 }}
          name="startDate"
          label={t('startDateLabel')}
          component={DatePicker}
        />
      </FormRow>

      <FormRow>
        <DisorderSection />
      </FormRow>

      <FormRow>
        <TextField
          multiline
          style={{ width: 600 }}
          name="disorderRemarks"
          label={t('disorderRemarks')}
        />
      </FormRow>

      <FormRow>
        <Select
          style={{ width: 600 }}
          label={t('productPerformance')}
          getOptions={async () => client.getProductOptions()}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name="productStart.id"
        />
      </FormRow>

      <FormRow>
        <Select
          style={{ width: 600 }}
          label={t('careDemandType')}
          getOptions={async () => client.getCareDemandTypes()}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name="careDemandType.id"
        />
      </FormRow>

      {hoNOS && (
        <FormRow>
          <Radios
            label={t('HoNOSType')}
            name="hoNOSType"
            fieldProps={{
              initialValue:
                program?.hoNOSType?.id?.toString() ||
                program?.hoNOSType?.toString()
            }}
            formControlProps={{
              margin: 'none'
            }}
            radioGroupProps={{
              row: true
            }}
            data={hoNOS}
          />
        </FormRow>
      )}

      <FormRow>
        <TextField name="startIsiScore" label={t('isiScore')} />
      </FormRow>

      <FormRow>
        <Field
          label={t('referrerConsent')}
          name="referrerConsent"
          type="checkbox"
          component={Checkbox}
        />
      </FormRow>

      <FormRow>
        <Field
          label={t('specialistConsent')}
          name="specialistConsent"
          type="checkbox"
          component={Checkbox}
        />
      </FormRow>

      <FormRow>
        <Field
          name="startIsiSent"
          label={t('isiSent')}
          type="checkbox"
          component={Checkbox}
        />
      </FormRow>

      <Divider />

      <FormRow>
        <FilesSection
          typesToShow={['privacyStatement']}
          uploadedFileType={'privacyStatement'}
        />
      </FormRow>

      <FormRow>
        <FilesSection
          typesToShow={['treatmentAgreement']}
          uploadedFileType={'treatmentAgreement'}
        />
      </FormRow>

      <FormRow>
        <FilesSection
          typesToShow={['hoNOSResult']}
          uploadedFileType={'hoNOSResult'}
        />
      </FormRow>

      {missingFields && (
        <Alert severity="warning">
          {t('missingFieldsWarning')} {missingFields}.
        </Alert>
      )}

      <AlertDialog
        fullWidth
        maxWidth={false}
        content={<LetterTemplate programId={programId} />}
        title={t('alertTitle')}
        confirmText={t('confirmText')}
        render={({ onClick }) => (
          <Button
            disabled={!!missingFields}
            onClick={onClick}
            type="submit"
            color="primary"
          >
            {t('alertTitle')}
          </Button>
        )}
      />
    </>
  )
}

export default StartTreatmentProgramSection
