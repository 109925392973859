import FormControl from '@material-ui/core/FormControl'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { default as MuiTextField } from '@material-ui/core/TextField/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React from 'react'
import { TFunnelStatusOption } from './types'

const TreatmentStatusSelector = ({
  options,
  label = '',
  value = 5,
  onChange
}: {
  options: TFunnelStatusOption[]
  label: string
  value: number
  onChange: (statusId) => void
} & RouteComponentProps) => {
  const getOptionById = (id: number) =>
    options.find((option) => option.id === id)

  return (
    <FormControl
      style={{
        width: 600
      }}
    >
      <Autocomplete
        disableClearable
        options={options}
        getOptionLabel={(treatmentStatus) => treatmentStatus.name}
        onChange={(_event, value) => {
          return onChange(value?.id)
        }}
        defaultValue={getOptionById(value)}
        renderInput={(params) => {
          return <MuiTextField {...params} label={label} />
        }}
      />
    </FormControl>
  )
}

export default withRouter(TreatmentStatusSelector)
