import FormRow from '../../FormRow'
import { Select } from '../../../../react-final-form-wrappers'
import { client } from '../../../../client'
import { useTranslation } from 'react-i18next'
import PsychologistSelector from '../../../../components/PsychologistSelector/PsychologistSelector'
import { useField } from 'react-final-form'
import { useEffect } from 'react'

const TherapistSection = () => {
  const { t } = useTranslation('therapistSection')

  // NOTE: this is an ugly way of doing a "fallback default"
  const {
    input: { value: occupationType, onChange: setOccupationType }
  } = useField('primaryPsychologist.occupationType.id')

  useEffect(() => {
    if (typeof occupationType !== 'number') {
      setOccupationType(5) // TODO: make this not hardcoded
    }
  }, [occupationType, setOccupationType])

  return (
    <>
      <FormRow>
        <PsychologistSelector
          label={t('primaryPsychologist')}
          name="primaryPsychologist.id"
          style={{ width: 300 }}
        />
      </FormRow>

      <FormRow>
        <Select
          style={{ width: 300 }}
          label={t('primaryPsychologistOccupationType')}
          getOptions={async () => client.getOccupationTypeOptions()}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name="primaryPsychologist.occupationType.id"
        />
      </FormRow>

      <FormRow>
        <PsychologistSelector
          label={t('secondaryPsychologist')}
          name="secondaryPsychologist.id"
          style={{ width: 300 }}
        />
      </FormRow>
    </>
  )
}

export default TherapistSection
