import FormRow from '../../FormRow'
import { Select } from '../../../../react-final-form-wrappers'
import { client } from '../../../../client'
import TreatmentProgramSelector from './TreatmentProgramSelector'
import { PlusCircle } from 'mdi-material-ui'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { AlertDialog } from '../../../../components/AlertDialog'
import Grid from '@material-ui/core/Grid'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TextField } from 'mui-rff'
import { useState } from 'react'
import useEffectAsync from '../../../../hooks/useEffectAsync'

const TreatmentProgramSection = ({ match }) => {
  const { patientId, treatmentProgramId } = match.params
  const { t } = useTranslation('treatmentProgramSection')
  const [treatmentProgramUUID, setTreatmentProgramUUID] = useState<
    string | null
  >(null)

  const handleCreateProgram = async () => {
    const program = await client.addTreatmentProgram(
      { patient: { id: match.params.patientId } },
      treatmentProgramId
    )
    window.location.href = `/clienten/${match.params.patientId}/trajecten/${program.id}`
  }

  const handleRemoveProgram = async () => {
    await client.removeTreatmentProgram(treatmentProgramId)
    const programIds = await client.getSortedTreatmentPrograms(patientId)
    if (programIds.length) {
      const latestProgramId = programIds[programIds.length - 1]
      window.location.href = `/clienten/${patientId}/trajecten/${latestProgramId}`
    } else {
      window.location.href = '/'
    }
  }

  useEffectAsync(async () => {
    const treatmentProgram = await client.getTreatmentProgramById(
      treatmentProgramId
    )
    if (treatmentProgram.uuid) setTreatmentProgramUUID(treatmentProgram.uuid)
  }, [])

  return (
    <>
      <Grid container direction="row">
        <Grid item>
          <TreatmentProgramSelector />
        </Grid>
        <Grid item>
          <IconButton aria-label="Add" onClick={handleCreateProgram}>
            <PlusCircle color="primary" />
          </IconButton>
        </Grid>
        <Grid item>
          {/*@ts-ignore*/}
          <AlertDialog
            text={t('alertDialogText')}
            title={t('alertTitle')}
            cancelText={t('alertCancel')}
            confirmText={t('alertConfirm')}
            onConfirm={handleRemoveProgram}
            render={({ onClick }) => (
              <IconButton aria-label="Add" onClick={onClick}>
                <DeleteIcon color="disabled" />
              </IconButton>
            )}
          />
        </Grid>
      </Grid>
      <FormRow>
        <TextField
          style={{ width: 300 }}
          name="treatmentType.uuid"
          label={t('UUID')}
          value={treatmentProgramUUID || ''}
          // @ts-ignore not sure if it works, but let's not break it if it does
          readOnly
        ></TextField>
      </FormRow>
      <FormRow>
        <TextField
          style={{ width: 300 }}
          name="treatmentType.productCode"
          label={t('productCode')}
          value={treatmentProgramId || ''}
          // @ts-ignore not sure if it works, but let's not break it if it does
          readOnly
        ></TextField>
      </FormRow>
      <FormRow>
        <Select
          style={{ width: 300 }}
          label={t('practiceLabel')}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          getOptions={async () => client.getPracticeOptions(true)}
          name="practice.id"
        />
      </FormRow>
      <FormRow>
        <Select
          style={{ width: 300 }}
          label={t('treatmentType')}
          getOptions={async () => client.getTreatmentTypeOptions()}
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name="treatmentType.id"
          MenuItemProps={(i) => {
            return {
              disabled: i.id === 1 // Generalistische Basis GGZ (2021 en eerder) - outdated treatmentType
            }
          }}
        />
      </FormRow>
      <FormRow>
        <TextField
          fullWidth
          multiline
          name="initialRemarks"
          label={t('remarksLabel')}
        />
      </FormRow>
    </>
  )
}

export default withRouter(TreatmentProgramSection)
