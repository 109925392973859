import {
  Checkbox,
  DatePicker,
  Field,
  Select
} from '../../../../react-final-form-wrappers'
import FormRow from '../../FormRow'
import { client } from '../../../../client'
import { TextField } from 'mui-rff'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { FilesSection } from '../FilesSection'

const EndTreatmentProgramSection = () => {
  const { t } = useTranslation('endTreatmentSection')

  const {
    input: { value: isLegacy }
  } = useField('isLegacy')

  return (
    <>
      {isLegacy && (
        <FormRow>
          <Select
            style={{ width: 500 }}
            label={t('performanceLabel')}
            getOptions={async () => client.getProductOptions()}
            getOptionLabel={(i) => i.name}
            getOptionValue={(i) => i.id}
            name="productEnd.id"
          />
        </FormRow>
      )}
      <FormRow>
        <Field
          name="endIsiSent"
          label={`ISI ${t('endSent')}`}
          type="checkbox"
          component={Checkbox}
        />
      </FormRow>
      <FormRow>
        <Field
          name="cqiSent"
          label={`Evaluatie ${t('sent')}`}
          type="checkbox"
          component={Checkbox}
        />
      </FormRow>
      <FormRow>
        <TextField
          name="endIsiScore"
          label={`ISI-score (${t('endMeasurement')})`}
        />
      </FormRow>
      <FormRow>
        <Field
          style={{ width: 300 }}
          name="endDate"
          label={t('endDateLabel')}
          component={DatePicker}
        />
      </FormRow>
      <FormRow>
        <Select
          style={{ width: 300 }}
          label={t('lastStep')}
          getOptions={async () =>
            isLegacy
              ? client.getClosingReasonOptions()
              : client.getClosingReasonOptions(false)
          }
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name="closingReason.id"
        />
      </FormRow>

      <FormRow>
        <FilesSection
          uploadedFileType={'endLetter'}
          typesToShow={['endLetter']}
        />
      </FormRow>
    </>
  )
}

export default EndTreatmentProgramSection
