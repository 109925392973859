import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import * as types from '../../../types'
import { useHistory } from 'react-router-dom'
import { useField } from 'react-final-form'
import { client } from '../../../client'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { AlertDialog } from '../../../components/AlertDialog'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { TherapistSelector } from './TherapistSelector'

export const AssignTherapistDialog = ({
  funnel,
  setLoadingAssignPsychologist,
  loadingAssignPsychologist
}) => {
  const { t } = useTranslation('patientDetailsPage')

  const [psychologistOptions, setPsychologistOptions] = useState<
    types.Psychologist[]
  >([])

  const [selectedPsychologist, setSelectedPsychologist] = useState<any>()
  const history = useHistory()
  const remarksField = useField('remarks')

  const {
    patient: { id: patientId }
  } = funnel

  const handleCreateTreatmentProgram = async () => {
    setLoadingAssignPsychologist(true)
    const { id: programId } = await client.addTreatmentProgram({
      primaryPsychologist: { id: selectedPsychologist.id },
      insuranceNumber: funnel.insuranceNumber,
      initialRemarks: remarksField.input.value,
      patient: {
        id: patientId
      }
    })
    await client.setFunnelTreatmentProgram(funnel.id, programId, 13)
    window.location.href = `/clienten/${patientId}/trajecten/${programId}`
  }

  useEffectAsync(async () => {
    const psychologistOptions = await client.getPsychologistOptions(true)
    const sortedPsychologistOptions = psychologistOptions.sort((a, b) =>
      a.user.firstName.localeCompare(b.user.firstName)
    )
    setPsychologistOptions(sortedPsychologistOptions)
  }, [])

  if (!psychologistOptions || loadingAssignPsychologist) {
    return <LoadingIndicator />
  }

  return (
    <AlertDialog
      fullWidth
      maxWidth="md"
      text={t('alertText')}
      content={
        <Grid container spacing={2}>
          <Grid item sm={12} md={4}>
            <TherapistSelector
              options={psychologistOptions}
              onChange={setSelectedPsychologist}
            />
          </Grid>
        </Grid>
      }
      title={t('alertTitle')}
      cancelText={t('alertCancel')}
      confirmText="OK"
      onConfirm={handleCreateTreatmentProgram}
      render={({ onClick }) =>
        funnel.treatmentProgram ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              history.push(
                `/clienten/${patientId}/trajecten/${funnel.treatmentProgram.id}`
              )
            }
          >
            {t('buttonFile')}
          </Button>
        ) : (
          <Button variant="outlined" color="primary" onClick={onClick}>
            {t('buttonAssign')}
          </Button>
        )
      }
    />
  )
}
